import { ButtonBase, ButtonBaseProps, Typography } from "@mui/material"
import { Interval } from "luxon"
import { ElementType } from "react"

export type AvailabilityTimeslotStyle = "available" | "unavailable"

export type AvailabilityTimeslotProps<C extends ElementType = 'button'> = ButtonBaseProps<C, { component?: C }> & {
    timeslot: Interval
    secondaryText?: string
    timeslotStyle: AvailabilityTimeslotStyle
}

const styles: Record<AvailabilityTimeslotStyle, ButtonBaseProps["sx"]> = {
    available: {
        backgroundColor: "lightgreen",
        borderColor: "text.secondary"
    },
    unavailable: {
        color: "text.disabled",
        borderColor: "text.disabled",
    }
}

export const AvailabilityTimeslot = <C extends ElementType = 'button'>({
    timeslot,
    secondaryText,
    timeslotStyle,
    ...props
}: AvailabilityTimeslotProps<C>) => {
    const pointerEvents = props.onClick ? props.pointerEvents : 'none'

    return <ButtonBase {...props} sx={{
        py: 1,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 2,
        border: "1px solid black",
        pointerEvents,
        ...styles[timeslotStyle],
        ...props?.sx
    }}>
        <Typography variant="body1">{timeslot.start.toFormat("HH:mm")}</Typography>
        {secondaryText && <Typography variant="body2" color="text.secondary">{secondaryText}</Typography>}
    </ButtonBase>
}