import { Permission } from "@marketpartner/backend-api"
import { SelectProps, SxProps } from "@mui/material"
import { FC } from "react"
import { AppTemplateIdSelector } from "src/app-templates/templates/AppTemplateIdSelector"
import { AppVersionSelector } from "src/app-templates/templates/AppTemplateVersionSelector"
import { Bar } from "src/common/layout/Bar"
import { ifHas } from "src/login/IfHas"


export type AppTemplateSelectorProps = {
    templateId: string | null
    onChangeTemplateId: (templateId: string | null) => void
    version: string | null
    onChangeVersion: (version: string | null) => void
    sx?: SxProps
    size?: SelectProps["size"]
}

export const AppTemplateSelector: FC<AppTemplateSelectorProps> = ({
    templateId,
    onChangeTemplateId,
    version,
    onChangeVersion,
    sx,
    ...props
}) => {

    const selectTemplate = (newTemplateId: string | null) => {
        if (templateId) {
            onChangeVersion(null)
        }
        onChangeTemplateId(newTemplateId)
    }

    return ifHas(Permission.AppAdmin, undefined, undefined, <Bar spacing={2} sx={sx}>
        <AppTemplateIdSelector
            label="Template"
            templateId={templateId}
            onChangeTemplateId={selectTemplate}
            required
            fullWidth
            {...props}
        />
        <AppVersionSelector
            label="Version"
            templateId={templateId}
            version={version}
            onChangeVersion={onChangeVersion}
            required
            fullWidth
            {...props}
        />
    </Bar>)
}