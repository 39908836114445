import { ComputedColumnValues, Registration } from "@marketpartner/backend-api"
import { Box, Fade, Typography } from "@mui/material"
import { GridSortItem, UncapitalizedGridProSlotsComponent } from "@mui/x-data-grid-pro"
import { FC, useCallback, useMemo, useState } from "react"
import { UserUsageLink } from "src/activities/user-usage/UserUsageLink"
import { ContextMenuButton, ContextMenuButtonProps } from "src/common/menus/ContextMenuButton"
import { getDynamicColumnDefinitions } from "src/common/grid/dynamic-columns"
import { ExportColumnDefinition } from "src/common/grid/grid-export"
import { QueryDataGridProps } from "src/common/grid/QueryDataGrid"
import { EventDateTimeText } from "src/common/time/DateTimeText"
import { useRegistrationContextMenu } from "src/registrations/actions/RegistrationContextMenu"
import { RegistrationApprovalButtons } from "src/registrations/approval/RegistrationApprovalButtons"
import { BaseRegistrationsGrid } from "src/registrations/BaseRegistrationsGrid"
import { RegistrationsFooter, RegistrationsFooterProps } from "src/registrations/bulk/RegistrationsFooter"
import { NameAndEmail } from "src/registrations/NameAndEmail"
import { ProfileAvatar } from "src/registrations/profiles/ProfileAvatar"

const ignoredFields = ["title", "first_name", "last_name", "email"]

export type RegistrationWithComputedColumns = Registration & {
    computedValues: ComputedColumnValues
}

export type RegistrationsGridProps = QueryDataGridProps<RegistrationWithComputedColumns>

export const RegistrationsGrid: FC<RegistrationsGridProps> = ({
    ...props
}) => {
    const [selectedRegistrations, setSelectedRegistrations] = useState<Registration[]>([])
    const [sortModel, setSortModel] = useState<GridSortItem[]>([
        { field: 'approvalState', sort: 'asc' }
    ])

    const clearSelection = useCallback(
        () => setSelectedRegistrations([]),
        []
    )

    const componentProps = useMemo(() => ({
        footer: {
            selectedRegistrations,
            clearSelection,
        }
    }), [selectedRegistrations, clearSelection])

    return <BaseRegistrationsGrid
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        selectedRegistrations={selectedRegistrations}
        onChangeSelectedRegistrations={setSelectedRegistrations}
        checkboxSelection
        hideFooterRowCount
        slots={gridComponents}
        slotProps={componentProps as any}
        {...props}
    />
}

const GridFooterContainer: FC<RegistrationsFooterProps> = (props) => {
    return <Fade in={props.selectedRegistrations.length > 0}>
        <Box>
            <RegistrationsFooter {...props} />
        </Box>
    </Fade>
}

const gridComponents: Partial<UncapitalizedGridProSlotsComponent> = {
    footer: GridFooterContainer
}

export function buildRegistrationColumns(registrations: RegistrationWithComputedColumns[]): ExportColumnDefinition<RegistrationWithComputedColumns>[] {
    return [
        {
            field: "approvalState",
            headerName: "Actions",
            renderCell: ({ row }) => <>
                {row.type === "Primary" && <RegistrationApprovalButtons registration={row} />}
                <RegistrationContextMenuButton registration={row} sx={{ mr: -1 }} />
            </>,
            sortComparator: (_v1, _v2, param1, param2) => compareRegistrations(
                param1.api.getRow(param1.id),
                param2.api.getRow(param2.id)
            ),
            align: "right",
            width: 140,
        },
        {
            field: "networking_profile",
            headerName: "Profile",
            renderCell: ({ row }) => <ProfileAvatar registration={row} size={40} linkToProfile />,
            align: "center",
            excludeFromCsv: true,
            width: 80
        },
        {
            field: "email_and_name",
            headerName: "Name",
            renderCell: ({ row }) => <NameAndEmail registration={row} linkToRegistration />,
            valueGetter: ({ row }) => `${row.lastName}_${row.firstName}`,
            width: 350,
            excludeFromCsv: true,
        },
        {
            field: "categoryId",
            headerName: "Category",
            width: 150,
            renderCell: ({ row }) => row.type === "Primary" ?
                row.categoryId :
                <Typography variant="body2" color="text.secondary">Guest</Typography>,
        },
        {
            field: "registeredAt",
            headerName: "Registered",
            renderCell: ({ value }) => <EventDateTimeText dateTime={value} includeSeconds />,
            width: 220
        },
        {
            field: "attended",
            headerName: "Attended",
            width: 100,
            renderCell: ({ row, value }) => value ? <UserUsageLink user={row}>Yes</UserUsageLink> : "No"
        },
        {
            field: "id",
            headerName: "Id",
            width: 270,
        },
        {
            field: "accessToken",
            headerName: "Token",
            width: 160,
            renderCell: params => params.row.approvalState === "Approved" ?
                <Box>{params.value}</Box> :
                <Box style={{ color: "#9e9e9e", textDecoration: "line-through" }}>{params.value}</Box>
        },
        ...getDynamicColumnDefinitions(registrations, "fields", {
            filter: columnName => !ignoredFields.includes(columnName)
        }),
        ...getDynamicColumnDefinitions(registrations, "computedValues"),
    ]
}

function compareRegistrations(reg1: Registration, reg2: Registration) {
    if (reg1.approvalState === reg2.approvalState) {
        return reg1.registeredAt.toMillis() - reg2.registeredAt.toMillis()
    }
    return approvalSortOrder[reg1.approvalState] - approvalSortOrder[reg2.approvalState]
}

const approvalSortOrder = {
    "PendingApproval": 0,
    "Approved": 1,
    "Cancelled": 2,
    "Rejected": 3,
}

type RegistrationContextMenuButtonProps = Partial<ContextMenuButtonProps> & {
    registration: Registration
}

const RegistrationContextMenuButton: FC<RegistrationContextMenuButtonProps> = ({
    registration,
    ...props
}) => {
    const contextMenu = useRegistrationContextMenu()

    return <ContextMenuButton
        onClick={e => contextMenu.open({
            registration,
            anchorEl: e.currentTarget,
        })}
        {...props}
    />
}
