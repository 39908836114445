import { ActivityType, TimedActivityDurationCondition } from "@marketpartner/backend-api"
import { Delete, Edit } from "@mui/icons-material"
import { IconButton, Typography } from "@mui/material"
import { FC } from "react"
import { ActivityTypeIcon } from "src/activities/ActivityTypeIcon"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { ActivityLookupList } from "src/registrations/computed-columns/boolean-conditions/ActivityLookupList"
import { ConditionContainer } from "src/registrations/computed-columns/boolean-conditions/ConditionContainer"
import { useTimedActivityDurationConditionDialog } from "src/registrations/computed-columns/boolean-conditions/TimedActivityDurationConditionDialog"

export type EditableTimedActivityDurationConditionProps = {
    condition: TimedActivityDurationCondition
    onChange: (condition: TimedActivityDurationCondition) => void
    onDelete: () => void
}

export const EditableTimedActivityDurationCondition: FC<EditableTimedActivityDurationConditionProps> = ({
    condition,
    onChange,
    onDelete,
}) => {
    const editDialog = useTimedActivityDurationConditionDialog()

    return <ConditionContainer condition={condition}>
        <Bar>
            <ActivityTypeIcon type={ActivityType.timed} sx={{ mr: 1 }} />
            <Typography fontWeight="bold">Total activity duration ≥ {condition.targetDurationMinutes} m</Typography>
            <Spacer />
            <IconButton
                children={<Edit fontSize="small" />}
                onClick={() => editDialog.open({
                    formProps: {
                        condition,
                        actionName: "Update",
                        onSubmit: async condition => onChange(condition),
                    }
                })}
                color="primary"
            />
            <IconButton
                children={<Delete fontSize="small" />}
                onClick={onDelete}
                color="error"
            />
        </Bar>
        <ActivityLookupList lookups={condition.lookups} />
    </ConditionContainer>
}