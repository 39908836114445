import { BooleanExpression } from "@marketpartner/backend-api"
import { FC } from "react"
import { EditableActionCountCondition } from "src/registrations/computed-columns/boolean-conditions/EditableActionCountCondition"
import { EditableBooleanExpressionGroup } from "src/registrations/computed-columns/boolean-conditions/EditableBooleanExpressionGroup"
import { EditableTimedActivityDurationCondition } from "src/registrations/computed-columns/boolean-conditions/EditableTimedActivityDurationCondition"


export type EditableExpressionProps = {
    expression: BooleanExpression
    onChange: (expression: BooleanExpression) => void
    onDelete: () => void
}

export const EditableExpression: FC<EditableExpressionProps> = ({
    expression,
    onChange,
    onDelete,
}) => {
    switch (expression.type) {
        case "Group": return <EditableBooleanExpressionGroup
            group={expression}
            onChangeGroup={onChange}
            onDelete={onDelete}
        />
        case "TimedActivityDuration": return <EditableTimedActivityDurationCondition
            condition={expression}
            onChange={onChange}
            onDelete={onDelete}
        />
        case "ActionCount": return <EditableActionCountCondition
            condition={expression}
            onChange={onChange}
            onDelete={onDelete}
        />
    }
}