import { ActivityType, Scope, TimedActivityDurationCondition } from "@marketpartner/backend-api";
import { DialogTitle, Grid, TextField } from "@mui/material";
import { FC, useState } from "react";
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { EditableActivityLookups } from "src/registrations/computed-columns/EditableActivityLookups";


export type TimedActivityDurationConditionDialogProps = LockableDialogProps & {
    formProps: TimedActivityDurationConditionFormProps
}

const TimedActivityDurationConditionDialog: FC<TimedActivityDurationConditionDialogProps> = ({
    formProps,
    ...props
}) => {
    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Timed activity duration</DialogTitle>
        <TimedActivityDurationConditionForm {...formProps} />
    </LockableDialog>
}

export type TimedActivityDurationConditionFormProps = ExtendableDialogFormProps<TimedActivityDurationCondition> & {
    condition?: TimedActivityDurationCondition
}

const TimedActivityDurationConditionForm: FC<TimedActivityDurationConditionFormProps> = ({
    condition,
    ...props
}) => {
    const [targetDurationMinutes, setTargetDurationMinutes] = useState(condition?.targetDurationMinutes ?? 10)
    const [lookups, setLookups] = useState(condition?.lookups ?? [{
        id: "",
        localStartIso: null,
        localEndIso: null,
    }])

    return <DialogForm
        formData={{
            type: "TimedActivityDuration",
            targetDurationMinutes,
            lookups,
        }}
        isValid={lookups.length > 0 && lookups.every(lookup => lookup.id !== "")}
        {...props}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    value={targetDurationMinutes}
                    onChange={e => setTargetDurationMinutes(parseInt(e.target.value))}
                    type="number"
                    inputProps={{
                        min: 1,
                        step: 1,
                    }}
                    label="Target duration (minutes)"
                />
            </Grid>
            <Grid item xs={12}>
                <EditableActivityLookups
                    activityType={ActivityType.timed}
                    lookups={lookups}
                    onChangeLookups={setLookups}
                />
            </Grid>
        </Grid>
    </DialogForm>
}

export const useTimedActivityDurationConditionDialog = createPopupHook({
    element: TimedActivityDurationConditionDialog,
    scope: Scope.Event,
})