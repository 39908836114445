import { AppTemplate } from "@marketpartner/backend-api"
import { FC } from "react"
import { backend } from "src/common/api"
import { LoadedAutocomplete, LoadedAutocompleteProps } from "src/common/form-inputs/LoadedAutocomplete"


export type AppTemplateIdSelectorProps = Partial<LoadedAutocompleteProps<string, AppTemplate>> & {
    templateId: string | null
    onChangeTemplateId: (templateId: string | null) => void
}

export const AppTemplateIdSelector: FC<AppTemplateIdSelectorProps> = ({
    templateId,
    onChangeTemplateId,
    ...props
}) => {
    const templateRequest = backend.appTemplates.useGetAllTemplates([])

    return <LoadedAutocomplete
        {...props}
        label="Template"
        fetchRequest={templateRequest}
        selectedOption={templateId ? {id: templateId} : null}
        onSelectOption={option => onChangeTemplateId(option?.id ?? null)}
        placeholder="Select a template"
    />
}