import { BooleanExpression, BooleanExpressionGroup } from "@marketpartner/backend-api"
import { Add } from "@mui/icons-material"
import { Button, Stack, Typography } from "@mui/material"
import { FC, Fragment } from "react"
import { useAddConditionMenu } from "src/registrations/computed-columns/boolean-conditions/AddConditionMenu"
import { BooleanExpressionGroupHeader } from "src/registrations/computed-columns/boolean-conditions/BooleanExpressionGroupHeader"
import { ConditionContainer } from "src/registrations/computed-columns/boolean-conditions/ConditionContainer"
import { EditableExpression } from "src/registrations/computed-columns/boolean-conditions/EditableExpression"


export type EditableBooleanExpressionGroupProps = {
    group: BooleanExpressionGroup
    onChangeGroup: (group: BooleanExpressionGroup) => void
    onDelete: () => void
    isRoot?: boolean
}

export const EditableBooleanExpressionGroup: FC<EditableBooleanExpressionGroupProps> = ({
    group,
    onChangeGroup,
    onDelete,
    isRoot = false,
}) => {
    const addConditionMenu = useAddConditionMenu()

    const handleDelete = (idx: number) => onChangeGroup({
        ...group,
        expressions: group.expressions.filter((_, index) => index !== idx),
    })

    const handleChange = (idx: number, expression: BooleanExpression) => onChangeGroup({
        ...group,
        expressions: group.expressions.map((e, index) => index === idx ? expression : e),
    })

    return <ConditionContainer condition={group} isRoot={isRoot}>
        <BooleanExpressionGroupHeader
            group={group}
            onChangeGroup={onChangeGroup}
            onDelete={onDelete}
            isRoot={isRoot}
        />
        <Stack spacing={2} sx={{ pl: 2, my: 2 }}>
            {group.expressions.length === 0 ?
                <Typography color="text.secondary" sx={{ alignSelf: "center" }}>Group is empty</Typography> :
                group.expressions.map((expression, index) => <Fragment key={index}>
                    {index > 0 && <Typography fontWeight="bold">
                        {group.operator}
                    </Typography>}
                    <EditableExpression
                        expression={expression}
                        onChange={expression => handleChange(index, expression)}
                        onDelete={() => handleDelete(index)}
                    />
                </Fragment>)
            }
        </Stack>
        <Button
            children="Add condition"
            onClick={e => addConditionMenu.open({
                anchorEl: e.currentTarget,
                onAdd: expression => onChangeGroup({
                    ...group,
                    expressions: [...group.expressions, expression],
                }),
            })}
            startIcon={<Add />}
            color="inherit"
            sx={{ alignSelf: "flex-start" }}
        />
    </ConditionContainer>
}
