import { currencyAmountText, reorderBody, Scope } from "@marketpartner/backend-api";
import { FC } from "react";
import { useClient } from "src/clients/client-context";
import { backend } from "src/common/api";
import { EditableDialogList } from "src/common/dialogs/EditableDialogList";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { withNotification } from "src/common/notifications/with-notification";
import { useEvent } from "src/events/event-context";
import { useCreateTicketTypeDialog } from "src/payment/ticket-types/CreateTicketTypeDialog";
import { useDeleteTicketTypeDialog } from "src/payment/ticket-types/DeleteTicketTypeDialog";
import { useEditTicketTypeDialog } from "src/payment/ticket-types/EditTicketTypeDialog";


export type TicketTypesDialogProps = LockableDialogProps

export const TicketTypesDialog: FC<TicketTypesDialogProps> = ({
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    const ticketTypesQuery = backend.ticketTypes.useGetAll([client.id, event.id])
    const reorderMutation = backend.ticketTypes.useReorder(withNotification({
        errorMessage: "Error reordering ticket types"
    }))

    const createDialog = useCreateTicketTypeDialog()
    const editDialog = useEditTicketTypeDialog()
    const deleteDialog = useDeleteTicketTypeDialog()

    return <LockableDialog
        maxWidth="xs"
        fullWidth
        {...props}
    >
        <EditableDialogList
            itemsQuery={ticketTypesQuery}
            getPrimaryText={ticketType => ticketType.name}
            getSecondaryText={ticketType => ticketType.id}
            getTeritaryText={ticketType => currencyAmountText(ticketType.price, event.currencyCode)}
            addProps={{
                onClickAdd: () => createDialog.open({}),
            }}
            contextMenuProps={ticketType => ({
                onClickEdit: () => editDialog.open({ ticketType }),
                onClickDelete: () => deleteDialog.open({ ticketType }),
            })}
            reorderProps={{
                onReorder: types => reorderMutation.mutateAsync([client.id, event.id, reorderBody(types)]),
            }}
        />
    </LockableDialog>
}

export const useTicketTypesDialog = createPopupHook({
    element: TicketTypesDialog,
    scope: Scope.Event,
})