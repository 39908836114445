import { ActionCountCondition, ActivityType } from "@marketpartner/backend-api"
import { Delete, Edit } from "@mui/icons-material"
import { IconButton, Typography } from "@mui/material"
import { FC } from "react"
import { ActivityTypeIcon } from "src/activities/ActivityTypeIcon"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"
import { useActionCountConditionDialog } from "src/registrations/computed-columns/boolean-conditions/ActionCountConditionDialog"
import { ActivityLookupList } from "src/registrations/computed-columns/boolean-conditions/ActivityLookupList"
import { ConditionContainer } from "src/registrations/computed-columns/boolean-conditions/ConditionContainer"

export type EditableActionCountConditionProps = {
    condition: ActionCountCondition
    onChange: (condition: ActionCountCondition) => void
    onDelete: () => void
}

export const EditableActionCountCondition: FC<EditableActionCountConditionProps> = ({
    condition,
    onChange,
    onDelete,
}) => {
    const editDialog = useActionCountConditionDialog()

    return <ConditionContainer condition={condition}>
        <Bar>
            <ActivityTypeIcon type={ActivityType.action} sx={{ mr: 1 }} />
            <Typography fontWeight="bold">Action count ≥ {condition.targetCount}</Typography>
            <Spacer />
            <IconButton
                children={<Edit fontSize="small" />}
                onClick={() => editDialog.open({
                    formProps: {
                        condition,
                        actionName: "Update",
                        onSubmit: async condition => onChange(condition),
                    }
                })}
                color="primary"
            />
            <IconButton
                children={<Delete fontSize="small" />}
                onClick={onDelete}
                color="error"
            />
        </Bar>
        <ActivityLookupList lookups={condition.lookups} />
    </ConditionContainer>
}