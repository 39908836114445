import { Paper } from "@mui/material"
import { FC } from "react"
import { Page } from "src/common/layout/Page"
import { RoutedTabs } from "src/common/routing/RoutedTabs"
import { fullSize } from "src/common/styles"
import { EventFeed } from "src/feeds/EventFeed"
import { EventMeetingSettings } from "src/networking/meetings/settings/EventMeetingSettings"
import { MessagingDetails } from "src/networking/messaging/MessagingDetails"


export const NetworkingPage: FC = () => {

    return <Page
        title="Networking"
    >
        <Paper sx={fullSize}>
            <RoutedTabs tabs={[
                {
                    path: "messaging",
                    label: "Messaging",
                    element: <MessagingDetails />,
                    scrollable: true,
                },
                {
                    path: "meetings",
                    label: "Meetings",
                    element: <EventMeetingSettings />,
                    scrollable: true,
                },
                {
                    path: "feed",
                    label: "Feed",
                    element: <EventFeed />,
                },
            ]} />
        </Paper>
    </Page>
}