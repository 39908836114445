import { Permission } from '@marketpartner/backend-api'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, Collapse, Paper } from "@mui/material"
import { useClient } from "src/clients/client-context"
import { backend } from 'src/common/api'
import { Bar } from 'src/common/layout/Bar'
import { Page } from "src/common/layout/Page"
import { RoutedTabs } from 'src/common/routing/RoutedTabs'
import { fullSize } from 'src/common/styles'
import { useEvent } from "src/events/event-context"
import { useLoginContext } from 'src/login/LoginContext'
import { RegistrationCategories } from 'src/registrations/configuration/categories/RegistrationCategories'
import { RegistrationFlow } from "src/registrations/configuration/flow/RegistrationFlow"
import { GuestConfiguration } from 'src/registrations/configuration/guests/GuestConfiguration'
import { Registrations } from "src/registrations/Registrations"


export const RegistrationsPage = () => {
    const login = useLoginContext()
    const client = useClient()!
    const event = useEvent()!

    return <Page title="Registration">
        <Paper sx={{ ...fullSize }}>
            {login.hasPermission(Permission.EditEventDetails, client.id, event.id) ?
                <RegistrationTabs /> :
                <Registrations sx={{ pt: 0.5 }} />
            }
        </Paper>
    </Page>
}

const RegistrationTabs = () => {
    const client = useClient()!
    const event = useEvent()!
    const categoryCountQuery = backend.registrationCategories.useGetAll([client.id, event.id], {
        select: categories => categories.length,
    })

    return <RoutedTabs tabs={[
        {
            label: "Registrations",
            path: "registrations",
            element: <Registrations />,
        }, {
            label: <Bar>
                <Collapse orientation='horizontal' in={categoryCountQuery.data === 0}>
                    <WarningIcon color="warning" sx={{ mr: 1 }} />
                </Collapse>
                Categories
            </Bar>,
            path: "categories",
            element: <RegistrationCategories />
        }, {
            label: <Box display="flex" alignItems="center">
                Guest setup
            </Box>,
            path: "guests",
            element: <GuestConfiguration />,
            scrollable: true,
        }, {
            label: "Configuration",
            path: "configuration",
            element: <RegistrationFlow />,
            scrollable: true,
        }
    ]} />
}