import { Permission } from "@marketpartner/backend-api"
import { Edit } from "@mui/icons-material"
import { Box, Button, Chip, colors, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { Bar } from "src/common/layout/Bar"
import { BasicProperty } from "src/common/layout/BasicProperties"
import { Spacer } from "src/common/layout/Spacer"
import { EmailTemplateName } from "src/email/EmailTemplateName"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"
import { useEditRegistrationFlowDialog } from "src/registrations/configuration/flow/EditRegistrationFlowDialog"

export const RegistrationFlow: FC = () => {
    const client = useClient()!
    const event = useEvent()!
    const editDialog = useEditRegistrationFlowDialog()

    return <Stack p={2} spacing={2}>
        <Bar>
            <Typography variant="h5">
                Registration flow
            </Typography>
            <Spacer />
            {ifHas(Permission.EditEventDetails, client.id, event.id, <Button
                children="Edit"
                startIcon={<Edit />}
                variant="contained"
                onClick={() => editDialog.open({})}
            />)}
        </Bar>
        <Bar spacing={4} flexWrap="wrap" alignItems="flex-start" useFlexGap>
            <Stack spacing={1}>
                <Typography variant="h6">Email templates</Typography>
                <BasicProperty
                    name={"Already registered"}
                    value={<EmailTemplateName id={event.mailjetTemplates.alreadyRegistered} />}
                />
                <BasicProperty
                    name={"Pending approval"}
                    value={<EmailTemplateName id={event.mailjetTemplates.registrationPendingApproval} />}
                />
                <BasicProperty
                    name={"Approved"}
                    value={<EmailTemplateName id={event.mailjetTemplates.registrationApproved} />}
                />
                <BasicProperty
                    name={"Rejected"}
                    value={<EmailTemplateName id={event.mailjetTemplates.registrationRejected} />}
                />
                <BasicProperty
                    name={"Cancelled"}
                    value={<EmailTemplateName id={event.mailjetTemplates.registrationCancelled} />}
                />
                <BasicProperty
                    name={"Resend access link"}
                    value={<EmailTemplateName id={event.mailjetTemplates.resendAccessLink} />}
                />
            </Stack>
            <Stack>
                <Typography variant="h6">Access</Typography>
                <PropertyWithList
                    name="Access mode"
                    value={event.accessMode}
                    showListIfValue="AllowedDomains"
                    list={event.allowedDomains}
                />
            </Stack>
            <Stack>
                <Typography variant="h6">Approval</Typography>
                <PropertyWithList
                    name="Approval mode"
                    value={event.approvalMode}
                    showListIfValue="AutoApproveDomains"
                    list={event.autoApprovedDomains}
                />
            </Stack>
        </Bar>
    </Stack>
}

type PropertyWithListProps = {
    name: string
    value: string
    showListIfValue: string
    list: string[]
}

const PropertyWithList: FC<PropertyWithListProps> = ({
    name,
    value,
    showListIfValue,
    list
}) => {
    return <Stack sx={{ my: 1 }}>
        <span><b>{name}:</b> {value}</span>
        {value === showListIfValue && <ListValues values={list} />}
    </Stack>
}

type ListValuesProps = {
    values: string[]
}

const ListValues: FC<ListValuesProps> = ({
    values
}) => {
    return <Box display="flex" flexDirection="column" alignItems="flex-start" maxHeight={150} overflow="hidden auto" mr={2} pr={3} pt={1} borderTop={1} borderColor={colors.grey[300]}>
        {values.map(value => <Box key={value} mb={0.5}>
            <Chip size="small" label={value} />
        </Box>)}
    </Box>
}