import { EmailTemplate } from "@marketpartner/backend-api";
import { FC } from "react";
import { backend } from "src/common/api";
import { LoadedAutocomplete, LoadedAutocompleteProps } from "src/common/form-inputs/LoadedAutocomplete";


export type EmailTemplateSelectorProps = Partial<LoadedAutocompleteProps<string, EmailTemplate>> & {
    label?: string
    templateId: number | null
    onSelectTemplateId: (templateId: number | null) => void
}

export const EmailTemplateSelector: FC<EmailTemplateSelectorProps> = ({
    templateId,
    onSelectTemplateId,
    ...props
}) => {
    const templatesQuery = backend.email.useGetAllTemplates([])

    
    return <LoadedAutocomplete
        label="Email template"
        fetchRequest={templatesQuery}
        selectedOption={templatesQuery.data?.find(template => template.id === templateId?.toString()) ?? null}
        onSelectOption={template => onSelectTemplateId(template?.id ? parseInt(template.id) : null)}
        sx={{ minWidth: 250}}
        size="medium"
        {...props}
    />
}