import { AppTemplateScope, ClientAppTemplateScope, TemplateMetadata } from "@marketpartner/backend-api"
import { Checkbox, Collapse, FormControl, FormControlLabel, FormControlProps, FormGroup, FormHelperText, FormLabel } from "@mui/material"
import { UseQueryResult } from "@tanstack/react-query"
import { FC, useCallback, useLayoutEffect, useMemo, useState } from "react"


export type ClientAppTemplateScopeSelectorState = {
    scopes: Record<ClientAppTemplateScope, boolean>
    supportedScopes: Record<ClientAppTemplateScope, boolean> | undefined
    toggleScope: (scope: ClientAppTemplateScope) => void
    isLoading: boolean
    error?: string
}

const allScopes = {
    Client: true,
    Event: true,
}

export const useClientAppTemplateScopeSelector = (
    metadataRequest: UseQueryResult<TemplateMetadata>,
    initialScopes?: Record<ClientAppTemplateScope, boolean>,
): ClientAppTemplateScopeSelectorState => {
    const [scopes, setScopes] = useState<Record<ClientAppTemplateScope, boolean>>(initialScopes ?? allScopes)
    const isLoading = metadataRequest.isLoading || !metadataRequest.data
    const supportsClient = metadataRequest.data?.supportedScopes?.Client ?? false
    const supportsEvent = metadataRequest.data?.supportedScopes?.Event ?? false

    const supportedScopes = useMemo(
        () => !isLoading ? {
            Client: supportsClient,
            Event: supportsEvent,
        } : undefined,
        [isLoading, supportsClient, supportsEvent]
    )

    useLayoutEffect(() => {
        if (supportedScopes) {
            setScopes(scopes => {
                return {
                    Client: scopes.Client && supportedScopes.Client,
                    Event: scopes.Event && supportedScopes.Event,
                }
            })
        } else {
            setScopes(allScopes)
        }
    }, [supportedScopes])

    const toggleScope = useCallback((scope: ClientAppTemplateScope) => {
        setScopes(scopes => ({
            ...scopes,
            [scope]: !scopes[scope]
        }))
    }, [])

    let error: string | undefined
    if (!isLoading && supportedScopes) {
        if (!supportedScopes.Client && !supportedScopes.Event) {
            error = `Template only supports global deployment`
        } else if (!scopes.Client && !scopes.Event) {
            error = `Select at least one scope`
        }
    }

    return useMemo(() => ({
        scopes,
        supportedScopes,
        isLoading,
        toggleScope,
        error,
    }), [scopes, supportedScopes, isLoading, toggleScope, error])
}

export type ClientAppTemplateScopeSelectorProps = Partial<FormControlProps> & {
    state: ClientAppTemplateScopeSelectorState
}

export const ClientAppTemplateScopeSelector: FC<ClientAppTemplateScopeSelectorProps> = ({
    state,
    ...props
}) => {
    return <FormControl
        error={Boolean(state.error)}
        disabled={state.isLoading}
        {...props}
    >
        <FormLabel>
            Deployable as
        </FormLabel>
        <FormGroup>
            <ScopeCheckbox label="Client site" state={state} scope={AppTemplateScope.Client} />
            <ScopeCheckbox label="Event app" state={state} scope={AppTemplateScope.Event} />
        </FormGroup>
        <Collapse in={Boolean(state.error)}>
            <FormHelperText>
                {state.error ?? <>&nbsp;</>}
            </FormHelperText>
        </Collapse>
    </FormControl>
}

type ScopeCheckboxProps = {
    label: string
    state: ClientAppTemplateScopeSelectorState
    scope: ClientAppTemplateScope
}

const ScopeCheckbox: FC<ScopeCheckboxProps> = ({
    label,
    state,
    scope,
}) => {
    return <FormControlLabel label={label} control={<Checkbox
        checked={state.scopes[scope]}
        disabled={!state.supportedScopes || !state.supportedScopes[scope]}
        onChange={() => state.toggleScope(scope)}
    />} />
}