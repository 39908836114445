import { BooleanExpressionGroup } from "@marketpartner/backend-api"
import { BasicSelect, ClearableTextField } from "@marketpartner/mp-common-mui"
import { Cancel, CheckCircle, Delete, Edit } from "@mui/icons-material"
import { IconButton, Stack, Typography } from "@mui/material"
import { FC, useState } from "react"
import { Bar } from "src/common/layout/Bar"
import { Spacer } from "src/common/layout/Spacer"


export type BooleanExpressionGroupHeaderProps = {
    group: BooleanExpressionGroup
    onChangeGroup: (group: BooleanExpressionGroup) => void
    onDelete: () => void
    isRoot: boolean
}

export const BooleanExpressionGroupHeader: FC<BooleanExpressionGroupHeaderProps> = ({
    group,
    onChangeGroup,
    onDelete,
    isRoot,
}) => {
    const [isEditing, setIsEditing] = useState(false)

    const confirmEdit = (group: BooleanExpressionGroup) => {
        setIsEditing(false)
        onChangeGroup(group)
    }

    return <Stack sx={{
        height: 45,
        justifyContent: "center",
    }}>
        {isEditing ?
            <EditGroupHeader
                group={group}
                onChange={confirmEdit}
                onCancel={() => setIsEditing(false)}
                isRoot={isRoot}
            /> :
            <GroupHeader
                group={group}
                onStartEdit={() => setIsEditing(true)}
                onDelete={onDelete}
                isRoot={isRoot}
            />}
    </Stack>
}


type GroupHeaderProps = {
    group: BooleanExpressionGroup
    onStartEdit: () => void
    onDelete: () => void
    isRoot: boolean
}

const GroupHeader: FC<GroupHeaderProps> = ({
    group,
    onStartEdit,
    onDelete,
    isRoot,
}) => {
    return <Bar>
        <Stack>
            {group.name && <Typography fontWeight="bold">{group.name}</Typography>}
            <Bar spacing={1}>
                <Typography>
                    {group.expressions.length === 1 ? "1 condition" : `${group.expressions.length} conditions`}
                    {group.expressions.length > 1 && (group.operator === "AND" ?
                        <> - <b>All</b> must be true</> :
                        <> - <b>At least one</b> must be true</>
                    )}
                </Typography>
            </Bar>
        </Stack>
        <Spacer />
        <IconButton
            children={<Edit fontSize="small" />}
            onClick={onStartEdit}
            color="primary"
        />
        <IconButton
            children={<Delete fontSize="small" />}
            onClick={onDelete}
            color="error"
            disabled={isRoot || group.expressions.length > 0}
        />
    </Bar>
}

type EditGroupHeaderProps = {
    group: BooleanExpressionGroup
    onChange: (group: BooleanExpressionGroup) => void
    onCancel: () => void
    isRoot: boolean
}

const EditGroupHeader: FC<EditGroupHeaderProps> = ({
    group,
    onChange,
    onCancel,
    isRoot,
}) => {
    const [name, setName] = useState(group.name)
    const [operator, setOperator] = useState(group.operator)

    return <Bar>
        {!isRoot && <ClearableTextField
            label="Group name (optional)"
            value={name}
            onChange={e => setName(e.target.value)}
            onClear={() => setName("")}
            variant="standard"
            size="small"
        />}
        <Spacer />
        <BasicSelect
            label="Operator"
            options={["AND", "OR"]}
            value={operator}
            onChange={setOperator}
            size="small"
            variant="standard"
            sx={{ 
                width: 100,
                mr: 1,
            }}
        />
        <IconButton
            children={<CheckCircle fontSize="small" />}
            onClick={() => onChange({ ...group, name, operator })}
            color="success"
        />
        <IconButton
            children={<Cancel fontSize="small" />}
            onClick={onCancel}
            color="error"
        />
    </Bar>
}