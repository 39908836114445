import { AppTemplateScope } from "@marketpartner/backend-api"
import { Add } from "@mui/icons-material"
import { FC } from "react"
import { NewClientDeploymentStepper } from "src/app-templates/deployments/client/NewClientDeploymentStepper"
import { useAppEnabledStatuses } from "src/app-templates/deployments/useAppEnabledStatuses"
import { DialogButton, DialogButtonProps } from "src/common/dialogs/DialogButton"


export type CreateEventAppButtonProps = Partial<DialogButtonProps>

export const CreateEventAppButton: FC<CreateEventAppButtonProps> = (props) => {
    const { canDeploy } = useAppEnabledStatuses()
    
    if (!canDeploy) {
        return <></>
    }

    return <DialogButton
        text="Create app"
        icon={<Add />}
        color="primary"
        variant="contained"
        {...props}
        dialogProps={{
            fullWidth: true,
            maxWidth: "sm",
        }}
    >
        <NewClientDeploymentStepper scope={AppTemplateScope.Event} />
    </DialogButton>
}
