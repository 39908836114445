import { IntervalSet, MeetingCalendar, Registration } from "@marketpartner/backend-api"
import { Undo } from "@mui/icons-material"
import { Button, Stack, SxProps, Typography } from "@mui/material"
import { DateTime, Interval } from "luxon"
import { FC, useState } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Loading } from "src/common/loading/Loading"
import { useEvent } from "src/events/event-context"
import { AvailabilityTimeslot } from "src/networking/meetings/availability/AvailabilityTimeslot"
import { NetworkingTimeslots } from "src/networking/meetings/periods/NetworkingTimeslots"


export type EditableIndividualAvailabilityProps = {
    registration: Registration
    sx?: SxProps
}

export const EditableIndividualAvailability: FC<EditableIndividualAvailabilityProps> = ({
    registration,
    sx,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const calendarRequest = backend.meetings.useGetMeetingCalendar<MeetingCalendar>([client.id, event.id, registration.id])

    if (!registration.profile.profile_visible) {
        return unavailableMessage(
            "Profile not visible",
            "People cannot accept meeting requests if their networking profile is not visible.\nCheck their registration/profile form."
        )
    }
    if (!registration.profile.allow_meeting_requests) {
        return unavailableMessage(
            "Meeting requests disabled",
            "This person is not opted in to receive meeting requests. Check their registration/profile form."
        )
    }

    return <Loading
        sizing="fit-content"
        request={calendarRequest}
        render={calendar =>
            <LoadedIndividualAvailability calendar={calendar} />
        }
        sx={sx}
    />
}

const unavailableMessage = (
    reason: string,
    details: string,
) => <Stack sx={{
    pt: 4,
    alignItems: "center",
    textAlign: "center",
}}>
        <Typography variant="h6">{reason}</Typography>
        <Typography sx={{ whiteSpace: "pre-wrap" }}>{details}</Typography>
    </Stack>

type LoadedIndividualAvailabilityProps = {
    calendar: MeetingCalendar
}

const LoadedIndividualAvailability: FC<LoadedIndividualAvailabilityProps> = ({
    calendar
}) => {
    const client = useClient()!
    const event = useEvent()!
    const [additionalMeetingAvailability, setAdditionalMeetingAvailability] = useState(calendar.additionalMeetingAvailability)
    const [blockedMeetingAvailability, setBlockedMeetingAvailability] = useState(calendar.blockedMeetingAvailability)
    const effectiveAvailability = event.defaultMeetingAvailability
        .add(additionalMeetingAvailability)
        .subtract(blockedMeetingAvailability)
    const updateCalendarMutation = backend.meetings.useUpdateMeetingCalendar()

    const doUpdateCalendar = (
        additionalMeetingAvailability: IntervalSet,
        blockedMeetingAvailability: IntervalSet,
    ) => {
        setAdditionalMeetingAvailability(additionalMeetingAvailability)
        setBlockedMeetingAvailability(blockedMeetingAvailability)
        updateCalendarMutation.mutate([client.id, event.id, calendar.registrationId, {
            localAdditionalMeetingAvailability: additionalMeetingAvailability.toLocalIntervalList(),
            localBlockedMeetingAvailability: blockedMeetingAvailability.toLocalIntervalList(),
        }])
    }

    const clearCustomAvailability = (start: DateTime, end: DateTime) => doUpdateCalendar(
        additionalMeetingAvailability.subtract(start, end),
        blockedMeetingAvailability.subtract(start, end),
    )

    const setAvailability = (interval: Interval, available: boolean) => doUpdateCalendar(
        available ?
            additionalMeetingAvailability.add(interval) :
            additionalMeetingAvailability.subtract(interval),
        available ?
            blockedMeetingAvailability.subtract(interval) :
            blockedMeetingAvailability.add(interval),
    )

    return <NetworkingTimeslots
        periodControls={period => <Button
            children="Default"
            color="inherit"
            startIcon={<Undo />}
            onClick={() => clearCustomAvailability(period.start, period.end)}
        />}
        renderTimeslot={timeslot => {
            const isAvailable = effectiveAvailability.engulfs(timeslot)
            return <AvailabilityTimeslot
                timeslot={timeslot}
                timeslotStyle={isAvailable ? "available" : "unavailable"}
                onClick={() => setAvailability(timeslot, !isAvailable)}
            />
        }}
    />
}