import { BooleanExpression } from "@marketpartner/backend-api"
import { Collapse, Stack } from "@mui/material"
import FormHelperText from "@mui/material/FormHelperText"
import { FC, ReactNode } from "react"
import { getConditionValidationError } from "src/registrations/computed-columns/boolean-conditions/condition-validity"


export type ConditionContainerProps = {
    isRoot?: boolean
    condition: BooleanExpression
    children: ReactNode
}

export const ConditionContainer: FC<ConditionContainerProps> = ({
    isRoot,
    condition,
    children,
}) => {
    const error = getConditionValidationError(condition)

    const baseBackgroundColor = `rgba(128, 128, 128, ${isRoot ? 0 : 0.1})`

    return <Stack
        sx={{
            p: 1,
            pr: 0,
            borderLeftWidth: 1,
            borderLeftStyle: "solid",
            borderColor: theme => error ? theme.palette.error.main : theme.palette.grey[500],
            borderRadius: 2,
            background: theme => error ?
                `linear-gradient(90deg, ${theme.palette.error.light} 0%, ${baseBackgroundColor} 1%)` :
                baseBackgroundColor,
        }}
    >
        {children}
        <Collapse in={Boolean(error)}>
            <FormHelperText error>{error || "&nbsp;"}</FormHelperText>
        </Collapse>
    </Stack>
}
