import { ActivityType, BooleanExpression, Scope } from "@marketpartner/backend-api";
import { DataObject } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FC } from "react";
import { ActivityTypeIcon } from "src/activities/ActivityTypeIcon";
import { createPopupHook } from "src/common/dialogs/Popup";
import { LockableMenu, LockableMenuProps } from "src/common/menus/LockableMenu";
import { useActionCountConditionDialog } from "src/registrations/computed-columns/boolean-conditions/ActionCountConditionDialog";
import { useTimedActivityDurationConditionDialog } from "src/registrations/computed-columns/boolean-conditions/TimedActivityDurationConditionDialog";


export type AddConditionMenuProps = LockableMenuProps & {
    onAdd: (condition: BooleanExpression) => void
}

const AddConditionMenu: FC<AddConditionMenuProps> = ({
    onAdd,
    ...props
}) => {
    const timedActivityDialog = useTimedActivityDurationConditionDialog()
    const actionCount = useActionCountConditionDialog()

    const addGroup = (operator: "AND" | "OR") => onAdd({
        type: "Group",
        name: "",
        expressions: [],
        operator,
    })

    return <LockableMenu closeOnClick {...props}>
        <MenuItem onClick={() => {
            timedActivityDialog.open({
                formProps: {
                    onSubmit: async condition => onAdd(condition),
                    actionName: "Add",
                }
            })
        }}>
            <ListItemIcon><ActivityTypeIcon type={ActivityType.timed} /></ListItemIcon>
            <ListItemText primary="Timed activity duration" />
        </MenuItem>
        <MenuItem onClick={() => {
            actionCount.open({
                formProps: {
                    onSubmit: async condition => onAdd(condition),
                    actionName: "Add",
                }
            })
        }}>
            <ListItemIcon><ActivityTypeIcon type={ActivityType.action} /></ListItemIcon>
            <ListItemText primary="Action count" />
        </MenuItem>
        <MenuItem onClick={() => addGroup("AND")}>
            <ListItemIcon><DataObject /></ListItemIcon>
            <ListItemText>Condition group (AND)</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => addGroup("OR")}>
            <ListItemIcon><DataObject /></ListItemIcon>
            <ListItemText>Condition group (OR)</ListItemText>
        </MenuItem>
    </LockableMenu>
}

export const useAddConditionMenu = createPopupHook({
    element: AddConditionMenu,
    scope: Scope.Event,
})