import { Link, LinkProps } from "@mui/material"
import { FC } from "react"
import { Link as RouterLink } from 'react-router-dom'
import { UsageUser } from "src/activities/user-usage/UserUsagePopup"
import { useClient } from "src/clients/client-context"
import { link } from "src/common/routing/routes"
import { useEvent } from "src/events/event-context"


export type UserUsageLinkProps = Partial<LinkProps> & {
    user: UsageUser
}

export const UserUsageLink: FC<UserUsageLinkProps> = ({
    user,
    ...props
}) => {
    const client = useClient()!
    const event = useEvent()!

    return <Link
        component={RouterLink}
        to={link.toRegistrationActivity(client.id, event.id, user.id)}
        {...props}
    />
}