import { EventDetails } from "@marketpartner/backend-api"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { LoadedAutocomplete, LoadedAutocompleteProps } from "src/common/form-inputs/LoadedAutocomplete"
import { useEvent } from "src/events/event-context"

export type EventSelectorProps = Omit<LoadedAutocompleteProps<string, EventDetails>, "fetchRequest" | "selectedOption"  | "onSelectOption" | "label"> & {
    clientId?: string | null
    omitSelf?: boolean
    selectedId: string | null
    onSelectId: (id: string | null) => void
}

export const EventSelector: FC<EventSelectorProps> = ({
    clientId,
    omitSelf = false,
    onSelectId,
    selectedId,
    ...props
}) => {
    const client = useClient()
    const thisEvent = useEvent()
    clientId = clientId ?? client?.id
    const eventsQuery = backend.events.useGetEvents([clientId!], {
        enabled: Boolean(clientId),
        select: events => (events.filter(event =>
            !omitSelf ||
            !thisEvent ||
            event.id !== thisEvent.id
        ) as EventDetails[]).sort(sortByStartTime),
    })

    return <LoadedAutocomplete
        fetchRequest={eventsQuery}
        label="Event"
        selectedOption={eventsQuery.data?.find(event => event.id === selectedId) ?? null}
        onSelectOption={event => onSelectId(event?.id ?? null)}
        getSecondaryText={event => event.id}
        {...props}
    />
}

function sortByStartTime(a: EventDetails, b: EventDetails) {
    return b.startTime.toMillis() - a.startTime.toMillis();
}