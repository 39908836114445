import { BooleanExpression } from "@marketpartner/backend-api";


export const isValidConditionRecursive = (condition: BooleanExpression): boolean => {
    const error = getConditionValidationError(condition)
    if (!error && condition.type === "Group") {
        return condition.expressions.every(isValidConditionRecursive)
    }
    return !error
}

export const getConditionValidationError = (condition: BooleanExpression): string | undefined => {
    if (condition.type === "Group") {
        if (condition.expressions.length === 0) {
            return "Group must contain at least one condition"
        }
    }
}