import { EventDetails, GuestRegistration, PrimaryRegistration, RegistrationType, Scope } from '@marketpartner/backend-api'
import { MenuItem, MenuProps } from "@mui/material"
import { FC } from 'react'
import { createPopupHook } from 'src/common/dialogs/Popup'
import { ExportColumnDefinition, exportToXlsx } from "src/common/grid/grid-export"
import { LockableMenu } from 'src/common/menus/LockableMenu'
import { useEvent } from "src/events/event-context"
import { CustomExport } from 'src/registrations/custom-exports/useGetCustomExports'

type ExportMenuProps = MenuProps & {
    name: string
    data: Record<string, unknown>[]
    defaultColumns: ExportColumnDefinition[]
    onClose: () => void
    customExports?: CustomExport[]
}
const ExportMenu: FC<ExportMenuProps> = ({
    name,
    data,
    defaultColumns,
    customExports,
    ...props
}) => {
    const event = useEvent()!
    const handleDefaultExport = () => {
        doExport({ name, event, data, columns: defaultColumns })
    }
    const handleCustomExport = (customExport: CustomExport) => {
        const columns = customExport.includeDefaultColumns 
            ? combineDefaultColumns(defaultColumns, customExport.columns)
            : customExport.columns
        const dataWithEvent = data.map(row => ({ ...row, event }))
        doExport({ name, event, data: customExport.moveGuestsInline ? moveGuestsInline(dataWithEvent) : dataWithEvent, columns: columns })
    }

    return <LockableMenu {...props} closeOnClick>
        <MenuItem onClick={handleDefaultExport}>
            Default export 
        </MenuItem>
        { Array.isArray(customExports) && customExports.map(customExport => <MenuItem key={customExport.name} onClick={() => handleCustomExport(customExport)}>
            { customExport.name }
        </MenuItem>)}

    </LockableMenu>
}

export const useExportMenu = createPopupHook({
    element: ExportMenu,
    scope: Scope.Event
})

type ExportFunctionProps = {
    name: string
    event: EventDetails
    data: Record<string, unknown>[]
    columns: ExportColumnDefinition[]
}
export function doExport({name, event, data, columns}: ExportFunctionProps) {
    name = name.replaceAll(/[^a-zA-Z0-9_\- ]/g, "_")
    const filePrefix = event ? `${event.id}-${name}` : name
    if (data) {
        exportToXlsx(
            filePrefix,
            name,
            data,
            columns,
            { additionalTimezone: event?.timezone }
        )
    }
}

function moveGuestsInline(data: Record<string, unknown>[]) {
    const guests = data.filter(row => row.type === RegistrationType.Guest) as GuestRegistration[]
    const primaryRegistrations = data.filter(row => row.type === RegistrationType.Primary) as PrimaryRegistration[]
    return primaryRegistrations.map(row => {
        const guestsForPrimary = guests.filter(guest => guest.primaryRegistrationId === row.id)
        return {
            ...row,
            guests: guestsForPrimary
        }
    })
}

function combineDefaultColumns(defaultColumns: ExportColumnDefinition[], customColumns: ExportColumnDefinition[]): ExportColumnDefinition[] {
    return defaultColumns
        .map(col => {
            const customCol = customColumns.find(c => c.field === col.field)
            return customCol ?? col
        })
        .concat(customColumns.filter(c => !defaultColumns.find(d => d.field === c.field)))
}