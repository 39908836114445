import { ActionCountCondition, ActivityType, Scope } from "@marketpartner/backend-api";
import { DialogTitle, Grid, TextField } from "@mui/material";
import { FC, useState } from "react";
import { DialogForm, ExtendableDialogFormProps } from "src/common/dialogs/DialogForm";
import { LockableDialog, LockableDialogProps } from "src/common/dialogs/LockableDialog";
import { createPopupHook } from "src/common/dialogs/Popup";
import { EditableActivityLookups } from "src/registrations/computed-columns/EditableActivityLookups";


export type ActionCountConditionDialogProps = LockableDialogProps & {
    formProps: ActionCountConditionFormProps
}

const ActionCountConditionDialog: FC<ActionCountConditionDialogProps> = ({
    formProps,
    ...props
}) => {
    return <LockableDialog
        maxWidth="sm"
        fullWidth
        {...props}
    >
        <DialogTitle>Action count</DialogTitle>
        <ActionCountConditionForm {...formProps} />
    </LockableDialog>
}

export type ActionCountConditionFormProps = ExtendableDialogFormProps<ActionCountCondition> & {
    condition?: ActionCountCondition
}

const ActionCountConditionForm: FC<ActionCountConditionFormProps> = ({
    condition,
    ...props
}) => {
    const [targetCount, setTargetCount] = useState(condition?.targetCount ?? 1)
    const [lookups, setLookups] = useState(condition?.lookups ?? [{
        id: "",
        localStartIso: null,
        localEndIso: null,
    }])

    return <DialogForm
        formData={{
            type: "ActionCount",
            targetCount,
            lookups,
        }}
        isValid={lookups.length > 0 && lookups.every(lookup => lookup.id !== "")}
        {...props}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    value={targetCount}
                    onChange={e => setTargetCount(parseInt(e.target.value))}
                    type="number"
                    inputProps={{
                        min: 1,
                        step: 1,
                    }}
                    label="Target count"
                />
            </Grid>
            <Grid item xs={12}>
                <EditableActivityLookups
                    activityType={ActivityType.action}
                    lookups={lookups}
                    onChangeLookups={setLookups}
                />
            </Grid>
        </Grid>
    </DialogForm>
}

export const useActionCountConditionDialog = createPopupHook({
    element: ActionCountConditionDialog,
    scope: Scope.Event,
})