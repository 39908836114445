import { CustomContentCategory, Permission } from "@marketpartner/backend-api"
import { Segment } from "@mui/icons-material"
import { Button, Stack } from "@mui/material"
import { FC } from "react"
import { useClient } from "src/clients/client-context"
import { backend } from "src/common/api"
import { Bar } from "src/common/layout/Bar"
import { BarAndFlex } from "src/common/layout/BarAndFlex"
import { Spacer } from "src/common/layout/Spacer"
import { Loading } from "src/common/loading/Loading"
import { fullSize } from "src/common/styles"
import { useContentSubCategoriesDialog } from "src/content/sub-categories/ContentSubCategoriesDialog"
import { SubCategoryContent } from "src/content/sub-categories/SubCategoryContent"
import { useEvent } from "src/events/event-context"
import { ifHas } from "src/login/IfHas"


export type EditableContentCategoryProps = {
    category: CustomContentCategory
}

export const EditableContentCategory: FC<EditableContentCategoryProps> = ({
    category,
}) => {
    const client = useClient()!
    const event = useEvent()!
    const editSubCategoriesDialog = useContentSubCategoriesDialog()
    const subCategoriesQuery = backend.contentCategories.useGetCategories([client.id, event.id], {
        select: categories => categories
            .find(it => it.id === category.id)!
            .subCategories,
    })
    const entitiesQuery = backend.contentEntities.useGetEntities([client.id, event.id], {
        select: entities => entities
            .filter(it => it.categoryId === category.id),
    })

    return <BarAndFlex
        barContent={ifHas(Permission.EditEventDetails, client.id, event.id, <Bar spacing={1} px={2} py={1}>
            <Spacer />
            <Button
                children="Sub-categories"
                startIcon={<Segment />}
                onClick={() => editSubCategoriesDialog.open({ categoryId: category.id })}
            />
        </Bar>)}
    >
        <Stack sx={{ ...fullSize, overflowY: "auto" }}>
            <Loading
                sizing="fit-content"
                requests={[subCategoriesQuery, entitiesQuery] as const}
                render={([subCategories, entities]) => <>
                    <SubCategoryContent
                        category={category}
                        entities={entities.filter(it => it.subCategoryId === null)}
                    />
                    {subCategories.map(subCategory =>
                        <SubCategoryContent
                            key={subCategory.id}
                            category={category}
                            subCategory={subCategory}
                            entities={entities.filter(it => it.subCategoryId === subCategory.id)}
                        />
                    )}
                </>}
            />
        </Stack>
    </BarAndFlex>
}