import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Tooltip, useTheme } from "@mui/material";
import { FC } from 'react';
import { backend } from 'src/common/api';

export type EmailTemplateNameProps = {
    id: string | number | null
}

export const EmailTemplateName: FC<EmailTemplateNameProps> = ({id}) => {
    const templateNameQuery = backend.email.useGetAllTemplates([], {
        enabled: Boolean(id),
        select: templates => templates.find(template => template.id === id?.toString())?.name,
    })
    const theme = useTheme()

    if (!id) {
        return <i style={{color: theme.palette.text.secondary}}>
            None
        </i>
    }

    if (templateNameQuery.error) {
        return <Box display="flex" alignItems="center">
            {id}
            <Tooltip title="Error loading email templates">
                <WarningIcon style={{color: theme.palette.warning.main}}/>
            </Tooltip>
        </Box>
    }

    if (templateNameQuery.isLoading) {
        return <>&nbsp;</>
    }

    if (!templateNameQuery.data) {
        return <Box display="flex" alignItems="center">
            {id}
            <Tooltip title="Template does not exist">
                <ErrorIcon style={{color: theme.palette.error.main}}/>
            </Tooltip>
        </Box>
    }

    return <>{templateNameQuery.data}</>
}