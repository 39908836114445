import { BooleanComputedColumn } from "@marketpartner/backend-api";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { EditableBooleanExpressionGroup } from "src/registrations/computed-columns/boolean-conditions/EditableBooleanExpressionGroup";


export type BooleanComputedColumnFormProps = {
    column: BooleanComputedColumn
    onChangeColumn: (column: BooleanComputedColumn) => void
}

export const BooleanComputedColumnForm: FC<BooleanComputedColumnFormProps> = ({
    column,
    onChangeColumn,
}) => {

    return <Stack spacing={1}>
        <Typography variant="h6">Conditions</Typography>
        <EditableBooleanExpressionGroup
            group={column.expressions}
            onChangeGroup={expressions => onChangeColumn({ ...column, expressions })}
            onDelete={() => {}}
            isRoot
        />
    </Stack>
}
