import { LoadingButton, LoadingButtonProps } from "@mui/lab"
import { Button, DialogActions, DialogContent, SxProps } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { ReactNode } from "react"
import { useDialogContext } from "src/common/dialogs/LockableDialog"

type FormDataRecord = Record<string, unknown>

export type DialogFormProps<FormData extends FormDataRecord = FormDataRecord, SubmitResponse = unknown> = {
    actionName: string
    formData: FormData
    onSubmit: (formData: FormData) => Promise<SubmitResponse>
    children: ReactNode
    isValid?: boolean
    submitColor?: LoadingButtonProps["color"]
    cancelText?: string
    dialogContentStyle?: SxProps
}

export type ExtendableDialogFormProps<FormData extends FormDataRecord = FormDataRecord, SubmitResponse = unknown> = Omit<DialogFormProps<FormData, SubmitResponse>, "formData" | "children">

/**
 * Dialog form handles:
 * 
 * - Display of cancel/submit buttons
 * - Perfoming the submit request
 * - Disabling the form & locking the dialog while the submit is in progress
 */
export const DialogForm = <FormData extends FormDataRecord = FormDataRecord, SubmitResponse = unknown>({
    actionName,
    formData,
    onSubmit,
    children,
    isValid = true,
    submitColor = "primary",
    cancelText = "Cancel",
    dialogContentStyle,
}: DialogFormProps<FormData, SubmitResponse>) => {
    const dialogContext = useDialogContext()

    const submitMutation = useMutation({
        mutationFn: onSubmit,
        onMutate: () => dialogContext.disableClose(),
        onSettled: () => dialogContext.enableClose(),
        onSuccess: () => dialogContext.close(true),
    })

    return <fieldset disabled={submitMutation.isPending} style={{ minHeight: 0, minWidth: 0, display: "flex" }}>
        <form id="dialog-form" onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
            submitMutation.mutate(formData)
        }} style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <DialogContent sx={dialogContentStyle}>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dialogContext.close()} disabled={submitMutation.isPending} color="inherit">
                    {cancelText}
                </Button>
                <LoadingButton type="submit" color={submitColor} variant="contained" loading={submitMutation.isPending} disabled={!isValid}>
                    {actionName}
                </LoadingButton>
            </DialogActions>
        </form>
    </fieldset>
}